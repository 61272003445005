import Compressor from 'compressorjs'

export default {
  methods: {
    beforeRead(file) {
      // eslint-disable-next-line no-console
      console.log('beforeRead', file)
      if(Array.isArray(file)) {
        this.$notify({ type: 'danger', message: '一次只能选择一张照片' })
        return false
      }

      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        this.$notify({ type: 'danger', message: '只能上传JPG/PNG的图片' })
        return false
      }

      return new Promise((resolve, reject) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        new Compressor(file, {
          quality: 0.6,
          success: resolve,
          error(err) {
            reject(err)
            // eslint-disable-next-line no-console
            console.log(err.message)
          }
        })
      })
    }
  }
}
